.time-item {
    background: lightgrey;
    height: 54px;
    width: 100px;
    border-radius: 50px;
    border: 1px solid whitesmoke;
    margin: 5px 4px;
}

.active {
    border: 1px solid #0f4d61;
    background: #0f4d6140;
}