.switch-wrapper {
    border-radius: 20px;
    width: max-content;
    border: 1px solid #225EA8;
    padding: 5px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 6px 10px;
}