.servL-img {
    background: url(https://d1b747rczec55w.cloudfront.net/assets/packs/media/categories/4008-ce2698d5.svg) bottom center;
    background-size: cover;
    box-sizing: border-box;
    height: 45px;
    width: 45px;
    border-radius: 8px;
}

.wrapperL-box {
    padding: 10px 20px;
    background: transparent;
    justify-content: flex-start;
    height: 75px;
    margin: 7px 0;
}

.dist-input {
    height: 30px;
    outline: none;
    border: none;
    width: 100%;
}

.flexL-box {
    margin: 0 12px;
    flex: 1;
    border-bottom: 1px solid #e9eaec;
    height: 55px;
}

@media screen and (max-width: 400px) {
    .servL-img {
        width: 50px;
        height: 50px;
    }

    .wrapperL-box {
        height: 65px;
    }
}