.dashed-box_2 {
    border: 1px dashed lightgray;
    height: 200px;
    width: 300px;
    background: #f9f9f9;
    margin: 10px 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.img-box {
    height: 200px;
    width: 300px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    ;
}

#img {
    display: none;
}

#img2 {
    display: none;
}