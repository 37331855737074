.home-hero {
    box-sizing: border-box;
    width: 100%;
    /* overflow: hidden; */
}

.side-hero {
    background: url('../../assets/images/home_hero.svg') content-box center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 250px;
    width: 100%;
    border: none;
}

.side-img1 {
    background: url('../../assets/images/user_thumb_n.svg') bottom center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 300px;
    width: 80%;
    border: none;
    flex: 1;
}

.bold-header {
    text-align: left;
    font: normal normal normal 45px/55px Fredoka One;
    letter-spacing: 0px;
    color: #0f4d61;
}

.partial-line {
    position: relative;
    padding-bottom: 5px;
}

.text-marche {
    position: relative;
    padding-bottom: 5px;
}

.text-marche::before {
    content: "";
    position: absolute;
    left: 45%;
    margin-left: -25px;
    margin-top: -50px;
    width: 15%;
    height: 1px;
    bottom: 0;
    border-bottom: 1px solid #a6cc1f;
}

.partial-line::before {
    content: "";
    position: absolute;
    left: 0%;
    width: 35%;
    height: 1px;
    bottom: 0;
    border-bottom: 1px solid #a6cc1f;
}

@media screen and (max-width: 600px) {
    .bold-header {
        width: 90vw;
        font-size: 30px;
    }
}

@media screen and (max-width: 300px) {
    .bold-header {
        width: 90vw;
        font-size: 24px;
    }
}