body {
  margin: 0;
  background: #f9f9f9;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  flex: 1;
  height: 100vh;
}

.link {
  text-decoration: none;
  cursor: pointer;
}
.links {
  /* text-decoration: none; */
  cursor: pointer;
  text-decoration-color: white;
}

.pointer {
  cursor: pointer;
}

.row-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row-flex-space {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row-flex-space-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

/* Adding CSS manually is only necessary in JSFiddle,
   where CSS can't be inserted into the <head> */
.typed-cursor {
  opacity: 1;
  animation: typedjsBlink 0.7s infinite;
  -webkit-animation: typedjsBlink 0.7s infinite;
  animation: typedjsBlink 0.7s infinite;
}

@keyframes typedjsBlink {
  50% {
    opacity: 0.0;
  }
}

@-webkit-keyframes typedjsBlink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1;
  }
}

.typed-fade-out {
  opacity: 0;
  transition: opacity .25s;
  -webkit-animation: 0;
  animation: 0;
}