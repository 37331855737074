.side-img2 {
    background: url('https://d1b747rczec55w.cloudfront.net/assets/referral/referral-hero-55822d0dab462fc228e5193000f656c20c768914ac10f8996a1acddee1d1900a.svg') bottom center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px;
    width: 80%;
    border: none;
    flex: 1;
    border-radius: 10px;
}
