.wrapper {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #70707038;
    height: 56px;
    margin-bottom: 20px;
}

.input-bar {
    flex: 1;
    height: 100%;
    outline: none;
    border: none;
    padding: 0px 10px;
}

.button-advanced {
    height: 100%;
    background-color: #a6cc1f;
    color: white;
    font-size: 17px;
    font-family: Futura-Medium;
    outline: none;
    width: 100%;
    border: none;
    font-weight: 600;
}