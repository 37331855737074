.wrapper-box {
    padding: 10px;
    width: 410px;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    height: max-content;
}

.item-btn {
    height: 44px;
    width: 40%;
    border-radius: 30px;
}

@media screen and (max-width: 430px) {
    .wrapper-box {
        width: 330px;
    }
}