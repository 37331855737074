.custom-input {
    height: 42px;
    width: 40%;
    box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.03);
    -webkit-box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.03);
    border: 1px solid lightgray;
    background: #fdfdfd;
    border-radius: 10px;
    /* margin: 10px 0; */
    outline: #589897;
    padding: 0 10px;
    font-size: 16px;
    font-family: catamaran;
    font-weight: 500;
    margin-left: 0px;
}