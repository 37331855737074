.head-bar {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #70707038;
    opacity: 1;
    padding: 15px;
}

.offre_propos {
    border: 1px solid #707070;
    height: 45px;
}

.input-offre {
    flex: 1;
    height: 100%;
    border: none;
    outline: none;
}

.cfa-div {
    position: absolute;
    right: 0;
    height: 50px;
    width: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #939191;
}

.offre-demand {
    height: 45px;
    background-color: #a6cc1f;
    color: white;
    font-size: 17px;
    font-family: Futura-Medium;
    outline: none;
    border: none;
    font-weight: 500;
}

.offre-cancel {
    height: 45px;
    background-color: #0f4d61;
    color: white;
    font-size: 17px;
    font-family: Futura-Medium;
    outline: none;
    border: none;
    font-weight: 500;
}