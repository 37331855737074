.btn-wrapper {
    height: 60px;
    width: 430px;
    margin: 25px 0px;
    background: white;
    border: 1px solid #0f4d61;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
    border-radius: 80px;
    box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.02);
    -webkit-box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.02);
    -moz-box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.02);
}

.animated-span {
    text-justify: auto;
    white-space: initial;

}

.icon-wrapper {
    height: 40px;
    width: 40px;
    border-radius: 55px;
    background: #a6cc1f;
    cursor: pointer;
}

@media screen and (max-width: 540px) {
    .btn-wrapper {
        width: 85%;
        align-self: center;
    }
}