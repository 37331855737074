.demande-img {
    background: url(https://d1b747rczec55w.cloudfront.net/assets/packs/media/categories/20-cdb8723d.svg) bottom center;
    background-size: cover;
    box-sizing: border-box;
    height: 130px;
    width: 100%;
    border-radius: 8px;
}

.wrapper-box-demande {
    border: 1px solid #e9eaec;
    padding: 20px;
    background: transparent;
    border-radius: 10px;
    margin: 7px 0;
    box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.03);
    -webkit-box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.03);
}