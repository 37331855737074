.map-container {
    height: 400px;
    width: 100%;
    border-radius: 20px;
}

.combo-wrapper-box {
    padding: 10px 20px;
    background: transparent;
    justify-content: flex-start;
    height: 75px;
    margin: 7px 0;
}

.combo-flex-box {
    margin: 0 12px;
    flex: 1;
    border-bottom: 1px solid #e9eaec;
    height: 55px;
}

.combo-wrapper {
    flex-grow: 1;
    width: 95%;
    height: 35px;
}

.combo-input {
    height: 30px;
    width: 100%;
    outline: none;
    border: none;
    z-index: 3;
    background: transparent;
}

@media screen and (max-width: 400px) {
    .servL-img {
        width: 50px;
        height: 50px;
    }

    .wrapperL-box {
        height: 65px;
    }
}