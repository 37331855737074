.box {
    width: 100%;
    height: 20px;
    display: none;
    position: absolute;
    top: 70px;
    background: white;
}

.box[data-expanded="true"] {
    height: 510px;
    padding: 10px 0;
    display: block;
    background: white;

}