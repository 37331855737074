.side-img3 {
    background: url('https://d1b747rczec55w.cloudfront.net/assets/become-jobber-alt/hero-b99dde73e7ad0336f80c4cf9a69fb107c9c3501add92944e179da022cfb2cfc9.svg') bottom center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
    width: 80%;
    border: none;
    flex: 1;
    border-radius: 10px;
}

.phone-img {
    background: url('https://d1b747rczec55w.cloudfront.net/assets/become-jobber-alt/app-1-cb1472309cfcd327f5c1ac938598bd6b797816ff8ce8e0045dec12bddcf9894b.png') bottom center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
    width: 80%;
    border: none;
    flex: 1;
    border-radius: 10px;
}

.phone-img1 {
    background: url('https://d1b747rczec55w.cloudfront.net/assets/become-jobber-alt/app-2-6026a55270abb3d55237c3e29b228c5fc7870fb81e36d003e890a523a53b1b2b.png') bottom center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
    width: 80%;
    border: none;
    flex: 1;
    border-radius: 10px;
}

.phone-img2 {
    background: url('https://d1b747rczec55w.cloudfront.net/assets/become-jobber-alt/app-3-b18b7817539dd17296b8f3ff144e9febe85ae6fbb15091ffc5c80773a75a1366.png') bottom center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
    width: 80%;
    border: none;
    flex: 1;
    border-radius: 10px;
}
