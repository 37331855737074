.dashed-box {
    border: 1px dashed lightgray;
    height: 300px;
    background: #f9f9f9;
    margin: 10px 0;
}

.img-box {
    height: 300px;
    width: 100%;
}

#img {
    display: none;
}