.bg-white {
    padding-top: 60px;
    background: white;
}

.box-outline {
    border: 1px solid #e9eaec;
    padding: 10px;
    border-radius: 5px;
    width: 270px;
    height: 220px;
}


@media screen and (max-width: 900px) {
    .box-outline {
        width: 90%;
    }
}