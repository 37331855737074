.offre-btn {
    height: 55px;
    background-color: #a6cc1f;
    color: white;
    font-size: 17px;
    font-family: Futura-Medium;
    outline: none;
    width: 80%;
    border: none;
    font-weight: 600;
}