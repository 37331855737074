.serv-img {
    background-size: cover;
    box-sizing: border-box;
    height: 65px;
    width: 65px;
    border-radius: 8px;
}

.wrapper-box-service {
    border: 1px solid #e9eaec;
    padding: 10px 20px;
    background: transparent;
    justify-content: flex-start;
    border-radius: 10px;
    height: 75px;
    margin: 7px 0;
    box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.03);
    -webkit-box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.03);
}

.flex-box {
    margin: 0 12px;
    flex: 1;
}

@media screen and (max-width: 400px) {
    .serv-img {
        width: 50px;
        height: 50px;
    }

    .wrapper-box {
        height: 65px;
    }
}