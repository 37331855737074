.side-img-job {
    background: url('https://d1b747rczec55w.cloudfront.net/assets/seo/misc/jobbing-6164c5ee14339325f90bf697260feb811c6bcc6012bbac7ef0e554d034dd89d3.jpg') bottom center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px;
    width: 80%;
    border: none;
    flex: 1;
    border-radius: 10px;
}

.side-img-job1 {
    background: url('https://d1b747rczec55w.cloudfront.net/assets/seo/misc/jobbing-france-571e7d96902770bfc1d18e8f41e6c45fe87956efb8e75bbcd4c89c228ed4d8e2.jpg') bottom center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px;
    width: 80%;
    border: none;
    flex: 1;
    border-radius: 10px;
}

