.user-logo-wrapper {
    position: relative;
    height: 100px;
    width: 100px;
    border-radius: 80px;

    /* border: 1px solid #e9eaec;
    padding: 10px;
    background: white;
    justify-content: flex-start;
    border-radius: 10px;
    height: 160px;
    margin: 7px 0;
    box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.03);
    -webkit-box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 2px 7px 5px 0px rgba(0, 0, 0, 0.03); */
}

.user-logo-img {
    height: 100%;
    width: 100%;
    border-radius: 80px;
}

.take-photo {
    position: absolute;
    background: #0f4d61;
    padding: 10px;
    border-radius: 20px;
    top: 60px;
    right: -8px;
}